import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import pressIcon from '../../assets/images/jobbox/press-icon.svg';

import bbqguruOne from '../../assets/images/bbqguru/new/bbqguru-1.webp';

import bbqguruTwo from '../../assets/images/bbqguru/new/bbq-2.webp';
import bbqguruThreeA from '../../assets/images/bbqguru/new/bbq-3-a.webp';
import bbqguruThreeB from '../../assets/images/bbqguru/new/bbq-3-b.webp';
import bbqguruThreeC from '../../assets/images/bbqguru/new/bbq-3-c.webp';
import bbqGuru from '../../assets/images/bbqguru/new/bbqguru-banner-2.webp';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class BbqGuru extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section>
            <img className="case-study-banner" src={bbqGuru} alt="banner" />
          </section>
          <section className="container">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">
                  App-Enabled BBQ Devices for Temperature Control
                </div>
                <div className="subtitle section-margin">
                  Achieving predictability and consistency preparing meals without having to hover
                  around the cooker
                </div>
                <div className="client-title section-margin">
                  CLIENT |<span style={{color: '#B11D21'}}> BBQ</span>
                  <span style={{color: '#2D51A4'}}> GURU</span>
                </div>

                <div className="para-mini-2 section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Improve stability of the software product which was key to the functioning of
                      BBQ Guru’s temperature control devices.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Reviewed the existing product, diagnosed its shortcomings, and ported it to a
                      more stable offering.
                    </p>
                  </div>
                </div>
                <div className="press-section section-margin">
                  <div className="d-flex align-items-center">
                    {' '}
                    <img className="icon " src={pressIcon} alt="" />
                    <div className="subheading ml-2">Press</div>
                  </div>
                  <p className="desc">
                    <a
                      className="press-desc"
                      href="https://www.forbes.com/sites/sap/2019/03/22/these-smoking-hot-gadgets-will-boost-your-backyard-grilling-game/?sh=5ca91af161f4"
                      target="_blank"
                      rel="noreferrer"
                    >
                      These smoking hot gadgets will boost your backyard grilling game
                    </a>{' '}
                    - Forbes BBQ
                    <br />
                    <a
                      className="press-desc"
                      href="https://www.coolthings.com/bbq-guru-cyberq-cloud-temperature-controller/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Guru CyberQ Cloud Temperature Controller
                    </a>{' '}
                    - CoolThings
                  </p>
                </div>
                <div className="video-container section-margin">
                  <iframe
                    src="https://www.youtube.com/embed/QHRV2jaXYvk"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
                <div className="col-11 mr-auto ml-auto">
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">
                      Client’s brief background and business problem
                    </div>
                    <p className="description">
                      <a
                        className="description"
                        href="https://www.bbqguru.com/"
                        style={{textDecoration: 'underline'}}
                        target="_blank" rel="noreferrer"
                      >
                        BBQ Guru
                      </a>{' '}
                      was a renowned manufacturer of BBQ devices which controlled the cooking
                      temperature in charcoal and wood-burning cookers. The first of its kind
                      technology invented by BBQ Guru allowed for slow cooking with temperature
                      controls within a few degrees of accuracy. Their{' '}
                      <a
                        className="description"
                        href="https://www.bbqguru.com/StoreNav?CategoryId=1"
                        style={{textDecoration: 'underline'}}
                        target="_blank" rel="noreferrer"
                      >
                        product line
                      </a>{' '}
                      included the award-winning PartyQ, DigiQ DX3, and CyberQ Cloud automatic
                      temperature control devices.
                    </p>
                    <br />
                    <p className="description">
                      BBQ Guru’s app-enabled temperature controllers allowed consumers the freedom
                      to prepare perfect meals without the hassle of constantly tending to their
                      cookers. The problem confronting BBQ Guru was their existing mobile
                      application which was prone to frequent crashes. Failure to provide a
                      consistent user experience resulted in poor app reviews and ratings.
                    </p>
                    <br />
                    <p className="description">
                      BBQ Guru reached out to several technology solution providers and decided to
                      engage Tintash to address these problems.
                    </p>
                    <br />
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-6 col-sm-9">
                      <img className="w-100" src={bbqguruOne} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      Tintash took the BBQ Guru team through an initial discovery phase. While
                      evaluating the existing code base, it soon became apparent that the choice of
                      React native as a cross-platform technology was not working for the client’s
                      product. Our team suggested shifting the applications to native iOS and
                      Android for improved performance and stability.x
                    </p>
                    <br />
                    <p className="description">
                      We used the discovery phase to evaluate several different options with the
                      client. Once it was decided to port the cross-platform app to native versions,
                      we proposed a budget and timeline for the project. It was essential to have
                      the app updates ready in time before the peak usage in spring and summer.
                    </p>
                    <br />
                    <p className="description">
                      Over the next two months, a team comprising two iOS and Android engineers each
                      worked with a project manager to try and drive down the app’s crash rate.
                      Owing to the challenging timeline, our team prioritized porting the apps over
                      building newer features. It immediately became evident that the stability and
                      consistency of Wi-Fi and data connections were critical to the app’s
                      successful functioning
                    </p>
                    <br />
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex">
                    <div className="col-lg-10 p-0">
                      <p className="description">
                        A team of hardware engineers investigating the customer's pain points from a
                        hardware standpoint discovered that the unstable internet connectivity was
                        not just because of the app but also the firmware.
                      </p>
                      <br />
                      <p className="description">
                        We fixed the problem with the lightweight IP (LwIP) stack and resolved
                        issues with the circular buffers carrying the IP traffic. As for the app’s
                        inability to consistently scan Wi-Fis in the vicinity, our team found out it
                        was in fact the hardware device which was limited to the North American
                        region’s bands.
                      </p>
                    </div>
                    <img className="col-2" src={bbqguruTwo} alt="jobbox" />
                  </div>
                  <div className="col-12 p-0">
                    <p className="description">
                      Porting the cross-platform app to native versions worked and we managed to
                      successfully drive down crash rates to virtually zero. This was reflected in
                      significantly improved user reviews and ratings. We also met our delivery
                      commitment, launching a stable and user friendly app within the target
                      timeline.
                    </p>
                    <br />
                    <p className="description">
                      In parallel to our efforts on the app, another team of hardware engineers
                      worked on the debugging process of the client's BBQ devices. There had been
                      issues with Bluetooth connectivity. We tested the hardware unit and found out
                      the problem was not just with the app but also with the firmware part.
                    </p>
                    <br />
                    <p className="description">
                      It was using synchronous frame structures to pass messages between the two
                      Bluetooth devices. That unnecessarily complicated the system, rendering it
                      unresilient to out-of-order and dropped messages. To overcome this, we
                      designed a new BLE service based on an optimized architecture to replace the
                      existing BLE communication layer. This allowed for asynchronous reading and
                      writing of device parameters
                    </p>
                    <p className="description">
                      Our efforts on the whole resolved a critical problem for the client. A stable
                      app update in time for their peak demand season afforded BBQ Guru the space to
                      focus efforts on marketing and sales. It also ideally set them up to gradually
                      introduce newer feature sets and further improve the user experience.
                    </p>
                    <br />
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-4 col-sm-9 p-0">
                      <img
                        className="w-100 sub-pic"
                        src={bbqguruThreeA}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="col-lg-4 col-sm-9 p-0">
                      <img
                        className="w-100 sub-pic"
                        src={bbqguruThreeB}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="col-lg-4 col-sm-9 p-0">
                      <img
                        className="w-100 sub-pic"
                        src={bbqguruThreeC}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="image-description text-center mt-5 mb-3 col-12">
                      BBQ Guru: in-app screens
                    </div>
                  </div>
                  <div className="section-margin" />
                </div>
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default BbqGuru;
